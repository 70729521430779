.ReactModal__Content {
  transition: transform 0.2s ease-in, opacity 0.1s ease-in;
  transform: scale(0.8) translateY(50px);
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.ReactModal__Content--before-close {
  transform: scale(0.8) translateY(50px);
  opacity: 0 !important;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
